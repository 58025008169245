<template>
  <div class="topbar" :class="$route.name === 'info' && 'topbar--dark'">
    <div
      class="header__container"
      :class="$route.name === 'info' && 'header__container--dark'"
    >
      <router-link to="/" @click.native="closM" class="header__logo"
        >HENFORD</router-link
      >
      <h2 :class="$route.name === 'home' && 'header__headline--mobile'">
        {{ headline }}
      </h2>
      <!-- <h2>{{ headline }}</h2> -->
    </div>
    <div
      class="nav__container"
      :class="$route.name === 'info' && 'nav__container--dark'"
    >
      <nav>
        <router-link to="/works"
          ><div class="dot dot__desk"></div>
          Works</router-link
        >
        <!-- <router-link to="/library">Sync Libray</router-link> -->
        <router-link to="/info"
          ><div class="dot dot__desk"></div>
          Info</router-link
        >
      </nav>
      <button @click="opneMen">
        <div class="hamburger" :class="open && 'hamb-top'"></div>
        <div class="hamburger" :class="open && 'hamb-middle'"></div>
        <div class="hamburger" :class="open && 'hamb-bottom'"></div>
      </button>
    </div>
    <!-- <transition name="slideIn" /> -->
    <transition name="slideIn">
      <div class="mobileM__container" v-if="open">
        <router-link to="/works" @click.native="closM">
          <div class="dot"></div>
          Works
        </router-link>
        <!-- <router-link to="/library">Sync Libray</router-link> -->
        <router-link to="/info" @click.native="closM">
          <div class="dot"></div>
          Info
        </router-link>
        <h2>{{ headline }}</h2>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    headline: String,
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    closM() {
      this.open = false;
      document.body.style.overflow = "auto";
    },
    opneMen() {
      this.open = !this.open ? true : false;

      if (this.open) {
        window.scrollTo({ top: 0 });
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
  // beforeRouteUpdate(to, from, next) {
  //   console.log(to)
  //   console.log(from)
  //   this.open = false;
  //   next();
  // },
  computed: {},
};
</script>

<style lang="scss" scoped>
.topbar {
  display: flex;
  width: 100%;
  padding: 28px;
  justify-content: space-between;
  // align-items: center;
  align-items: flex-start;
  position: absolute;
  isolation: auto;
  top: 0;
  left: 0;
  // z-index: 100;
  // background: white;
  &--dark {
    background: none;
  }

  @media screen and (max-width: 520px) {
    padding: 20px;
  }
}

.header {
  &__container {
    display: flex;
    align-items: center;
    gap: 28px;

    h2 {
      width: 30vw;
      font-size: 1.8vw;
      line-height: 120%;
      @include plain;
    }

    &--dark {
      h2,
      a,
      div {
        color: white;
      }
      .header__logo {
        mix-blend-mode: normal;
      }
    }

    @media screen and (max-width: 520px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 12px;
      h2 {
        display: none;
      }
      .header__headline--mobile {
        display: flex;
        font-size: 18px;
        width: 100%;
      }
    }
  }

  &__logo {
    @include helve;
    color: black;
    font-size: clamp(38px, 4.5vw, 90px);
    text-decoration: none;
    line-height: 100%;
    // mix-blend-mode: exclusion;
    @media screen and (max-width: 520px) {
      z-index: 20;
      color: white;
      mix-blend-mode: exclusion;
    }
  }
}

.nav {
  &__container {
    display: flex;
    gap: 18px;
    align-items: center;

    nav {
      @include plain;
      display: flex;
      font-size: 18px;
      gap: 18px;
      a {
        position: relative;
        .dot__desk {
          position: absolute;
          background: black;
          top: 28px;
          left: calc(50% - 3px);
          width: 6px;
          height: 6px;
          border-radius: 100%;
          display: none;
        }
      }
      a:hover {
        // text-decoration: underline;
        .dot__desk {
          display: block;
        }
      }

      @media screen and (max-width: 520px) {
        a {
          display: none;
        }
      }
      .router-link-exact-active {
        .dot__desk {
          display: block;
        }
      }
    }

    button {
      @media screen and (min-width: 520px) {
        display: none;
      }

      background: none;
      outline: none;
      border: none;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 12px;
      width: 46px;
      height: 46px;
      // background: salmon;
    }

    &--dark {
      nav a {
        color: white;
      }
      .dot__desk {
        background: white !important;
      }

      .hamburger {
        mix-blend-mode: normal;
      }
    }
  }
}

.hamburger {
  height: 2px;
  border-radius: 2px;
  line-height: 100%;
  background: white;
  mix-blend-mode: exclusion;
  z-index: 20;
  width: 100%;
  transition: all 300ms ease-in-out;
}

.hamb-top {
  transform-origin: 0 100%;
  transform: translate(0, -3px) rotate(45deg);
  width: 29px;
}

.hamb-middle {
  opacity: 0;
}

.hamb-bottom {
  transform-origin: 0 100%;
  transform: rotate(-45deg) translate(0, +3px);
  width: 29px;
}

.mobileM {
  &__container {
    @media screen and (min-width: 520px) {
      display: none;
    }

    z-index: 19;
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 36px;
    padding: 118px 20px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    background: black;

    .router-link-exact-active {
      .dot {
        display: block;
      }
    }

    a {
      color: white;
      @include plain;
      font-size: 48px;
      display: flex;
      align-items: center;
      gap: 16px;

      .dot {
        display: none;
        background: white;
        height: 8px;
        width: 8px;
        border-radius: 100%;
      }
    }
    h2 {
      color: white;
      font-size: 32px;
      font-weight: normal;
      position: absolute;
      padding: 0 20px;
      left: 0;
      bottom: 52px;
      @include plain;
    }
  }
}

.slideIn-enter-active {
  transition: all 0.3s ease;
}
.slideIn-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slideIn-enter, .slideIn-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(100vw);
}

// .nav {
//   &__container {
//     display: flex;
//     gap: 18px;
//     align-items: center;

//     nav {
//       @include plain;
//       display: flex;
//       font-size: 18px;
//       gap: 18px;
//       a {
//         position: relative;
//         .dot__desk {
//           position: absolute;
//           background: black;
//           top: 28px;
//           left: calc(50% - 3px);
//           width: 6px;
//           height: 6px;
//           border-radius: 100%;
//           display: none;
//         }
//       }
//       a:hover {
//         // text-decoration: underline;
//         .dot__desk {
//           display: block;
//         }
//       }

//       @media screen and (max-width: 520px) {
//         a {
//           display: none;
//         }
//       }
//       .router-link-exact-active {
//         .dot__desk {
//           display: block;
//         }
//       }
//     }

//     button {
//       // z-index: 20;
//       // background: white;
//       //background: red;
//       @media screen and (min-width: 520px) {
//         display: none;
//       }

//       background: none;
//       outline: none;
//       border: none;
//       cursor: pointer;
//       display: flex;
//       flex-direction: column;
//       justify-content: space-around;
//       padding: 12px;
//       width: 46px;
//       height: 46px;
//       // background: salmon;
//       @media screen and (max-width: 520px) {
//       z-index: 200;
//     }
//     }

//     &--dark {
//       nav a {
//         color: white;
//       }
//       .dot__desk {
//         background: white !important;
//       }

//       .hamburger {
//         mix-blend-mode: normal;
//       }
//     }

//     @media screen and (max-width: 520px) {
//       z-index: 200;
//     }
//   }
// }

// .hamburger {
//   height: 2px;
//   border-radius: 2px;
//   line-height: 100%;
//   background: white;
//   mix-blend-mode: difference;
//   // background: white;
//   // mix-blend-mode: difference;
//   // z-index: 21;
//   width: 100%;
//   transition: all 300ms ease-in-out;
// }

// .hamb-top {
//   transform-origin: 0 100%;
//   transform: translate(0, -3px) rotate(45deg);
//   width: 29px;
// }

// .hamb-middle {
//   opacity: 0;
// }

// .hamb-bottom {
//   transform-origin: 0 100%;
//   transform: rotate(-45deg) translate(0, +3px);
//   width: 29px;
// }

// .mobileM {
//   &__container {
//     @media screen and (min-width: 520px) {
//       display: none;
//     }

//     z-index: 19;
//     position: fixed;
//     display: flex;
//     flex-direction: column;
//     gap: 36px;
//     padding: 118px 20px;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100vh;
//     height: 100dvh;
//     background: black;

//     .router-link-exact-active {
//       .dot {
//         display: block;
//       }
//     }

//     a {
//       color: white;
//       @include plain;
//       font-size: 48px;
//       display: flex;
//       align-items: center;
//       gap: 16px;

//       .dot {
//         display: none;
//         background: white;
//         height: 8px;
//         width: 8px;
//         border-radius: 100%;
//       }
//     }
//     h2 {
//       color: white;
//       font-size: 32px;
//       font-weight: normal;
//       position: absolute;
//       padding: 0 20px;
//       left: 0;
//       bottom: 52px;
//       @include plain;
//     }
//   }
// }

// .slideIn-enter-active {
//   transition: all 0.3s ease;
// }
// .slideIn-leave-active {
//   transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
// }
// .slideIn-enter, .slideIn-leave-to
// /* .slide-fade-leave-active below version 2.1.8 */ {
//   transform: translateX(100vw);
// }
</style>
