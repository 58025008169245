<template>
  <div class="filterTitle__cont">
    <img v-if="mobOpen" src="../assets/img/Close.svg" alt="" />
    <h3>Filter</h3>
    <div class="filterTitle__mobFil" v-if="$store.state.selFilter !== 'All'">
      [{{ $store.state.selFilter }}]
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mobOpen: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.filterTitle {
  &__cont {
    @include plain;
    color: black;
    position: sticky;
    top: 32px;
    display: flex;
    align-items: center;
    gap: 8px;
    img {
      width: 16px;
      height: 16px;
      padding-bottom: 4px;
      margin-right: 8px;
      filter: brightness(0);
      @media screen and (min-width: 520px) {
        display: none;
      }
    }

    h3 {
      font-size: 22px;
      margin-bottom: 32px;
    }
    @media screen and (max-width: 520px) {
      position: block;
      top: 0;
      h3 {
        margin-bottom: 0px;
        padding-bottom: 12px;
        padding-top: 8px;
      }
    }
  }
  &__mobFil {
    @media screen and (min-width: 520px) {
      display: none;
    }
  }
}
</style>
