<template>
  <div class="info__page">
    <div class="info__container">
      <info-anchor class="info__index" :anchor="anchor" @toanch="getAnc" />
      <div class="content__container" v-if="loading">
        <img src="../assets/img/heroface.png" alt="" class="hero__imgAbs" />
        <div class="hero__container">
          <div class="hero__text">
            <h3 class="content__headline splitting" data-splitting="word">
              {{ about.headline }}
            </h3>
            <div class="hero__contacs">
              <div class="hero__ref">
                <p>{{ about.headline_contact }}</p>
                <a
                  class="mail"
                  href="#mailgo"
                  data-address="henfordmusic"
                  data-domain="gmail.com"
                  >henfordmusic@gmail.com</a
                >
                <a href="#mailgo" data-tel="+393480569791">+39 3480569791</a>
              </div>
              <div class="hero__links">
                <a href="https://vimeo.com/henford">Vimeo</a>
                <a href="https://www.instagram.com/henford_">Instagram</a>
              </div>
            </div>
          </div>

          <img
            v-show="loading"
            src="../assets/img/heroface.png"
            alt=""
            class="hero__img"
          />
        </div>
        <info-anchor
          class="info__indexMob"
          :anchor="'about'"
          @toanch="getAnc"
        />
        <div class="about__container trackCont" id="about">
          <div class="info__block">
            <h3 class="info__titleMob">About</h3>
            <PrismicImage :field="about.image_about_1" class="info__image" />
            <div class="info__blockText">
              <h3>About</h3>
              <PrismicRichText
                :html-serializer="htmlSerializer"
                :field="about.about_1"
              />
            </div>
          </div>
          <div class="info__block info__block--alt">
            <div class="info__blockText about__second">
              <PrismicRichText
                :html-serializer="htmlSerializer"
                :field="about.about_paragraph_2"
              />
              <a href="https://www.indiehub.it/">Discover IndieHub Studios</a>
            </div>
            <PrismicImage :field="about.about_image_2" class="info__image" />
          </div>
          <h3 class="about__highlight splitting" data-splitting="word">
            {{ about.about_highlight }}
          </h3>
        </div>
        <div class="disc__container trackCont" id="disc">
          <h3 class="info__titleMob">Discography</h3>
          <div class="info__block">
            <PrismicImage
              :field="about.discography_image_1"
              class="info__image"
            />
            <div class="info__blockText">
              <h3>Discography</h3>
              <PrismicRichText
                :field="about.discography_paragraph_1"
                :html-serializer="htmlSerializer"
              />
            </div>
          </div>
          <div class="info__block info__block--alt">
            <div class="info__blockText about__second">
              <PrismicRichText
                :field="about.discography_paragraph_2"
                :html-serializer="htmlSerializer"
              />
            </div>
            <PrismicImage
              :field="about.discography_image_2"
              class="info__image"
            />
          </div>
          <div class="info__block">
            <div class="disc__covers">
              <PrismicImage :field="about.cover_big" class="disc__coverBig" />
              <PrismicImage
                :field="about.cover_small_1"
                class="disc__coverSm1"
              />
              <PrismicImage
                :field="about.cover_small_2"
                class="disc__coverSm2"
              />
            </div>
            <div class="info__blockText disc__third">
              <PrismicRichText
                :field="about.discography_paragraph_3"
                :html-serializer="htmlSerializer"
              />
              <!-- <a href="/">View Cartella stampa</a> -->
            </div>
          </div>
        </div>
        <div class="contacts__container trackCont" id="contacts">
          <h3>Contacts</h3>
          <get-in-touch :dark="true" :info="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Lenis from "@studio-freight/lenis";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Splitting from "splitting";

import InfoAnchor from "@/components/infoAnchor.vue";
import GetInTouch from "@/components/GetInTouch.vue";
export default {
  components: {
    InfoAnchor,
    GetInTouch,
  },
  data() {
    return {
      about: null,
      loading: false,
      anchor: "",
      launch: false,
      lenis: null,
    };
  },
  watch: {
    // loading(n, o) {
    //   console.log(n)
    //   if (n === true) {
    //     this.launch = true
    //   }
    // }
  },
  methods: {
    htmlSerializer: function (type, element, text, children) {
      if (type === "paragraph")
        // return `<p className="splitting">${children}</p>`
        return `<p class="splitting parScro" data-splitting="word">${children}</p>`;
      return null;
    },
    getAnc(k) {
      var element = document.getElementById(k);
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    },
    async getContent() {
      const response = await this.$prismic.client.getSingle("about");
      this.about = response.data;
      this.loading = true;
    },
    trackBlock() {
      let options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.4,
      };

      let callback = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.anchor = entry.target.id;
          }
        });
      };

      let observer = new IntersectionObserver(callback, options);
      var targets = document.querySelectorAll(".trackCont");
      targets.forEach((target) => observer.observe(target));
    },
    raf(time) {
      this.lenis.raf(time);
      requestAnimationFrame(this.raf);
    },
  },
  mounted() {
    this.$nextTick(() => {
      gsap.registerPlugin(ScrollTrigger);
      this.lenis = new Lenis({
        lerp: 0.1,
        // smooth: true,
      });
      this.lenis.on("scroll", (e) => {
        // console.log(e)
      });
      requestAnimationFrame(this.raf);
    });
  },
  created() {
    this.getContent();
  },
  updated() {
    if (document.querySelector(".trackCont")) {
      this.trackBlock();
    }
    if (this.loading && !this.launch) {
      Splitting({
        by: "lines",
      });

      const para = document.querySelectorAll(".parScro");
      const high = document.querySelector(".about__highlight");
      const face = document.querySelector(".hero__img");
      const block = document.querySelectorAll(".info__block");
      const hero = document.querySelector(".hero__container");

      para.forEach((item) => {
        gsap.to(item, {
          scrollTrigger: {
            trigger: item,
            start: "top 80%",
            end: "bottom 20%",
            scrub: true,
            markers: false,
            onEnter: () => item.classList.add("inViewPar"),
          },
        });
      });
      gsap.to(high, {
        scrollTrigger: {
          trigger: high,
          start: "top 80%",
          end: "bottom 20%",
          scrub: true,
          markers: false,
          onEnter: () => high.classList.add("inViewHigh"),
        },
      });
      gsap.to(face, {
        scrollTrigger: {
          trigger: hero,
          start: "top", // Inizia l'animazione quando il testo raggiunge l'80% dal top del viewport
          end: "bottom",
          scrub: true, // Smooth dell'animazione durante lo scroll
          markers: false, // Questa opzione può essere rimossa, serve solo per mostrare i markers durante lo sviluppo
        },
        duration: 1, // Durata dell'animazione per ogni riga
        y: -40, // Muovi le righe verso l'alto di 50 pixe
        //stagger: 0.1, // Ritarda l'inizio dell'animazione per ogni riga di 0.1 secondi
        //delay: 0.5,   // Ritarda l'inizio totale dell'animazione di 0.5 secondi
      });
      // block.forEach((item) => {
      //   let rand = Math.random()
      //   gsap.to(item, {
      //     scrollTrigger: {
      //     trigger: block,
      //     start: "top 60%",  // Inizia l'animazione quando l'immagine raggiunge l'alto del viewport
      //     end: "bottom 60%", // Termina l'animazione quando l'immagine lascia il fondo del viewport
      //     scrub: true, // Smooth dell'animazione durante lo scroll
      //     markers: true, // Questa opzione può essere rimossa, serve solo per mostrare i markers durante lo sviluppo
      //   },
      //   duration: 1,  // Durata dell'animazione per ogni riga
      //   y: -140 * rand - 40, // Muovi l'immagine verso l'alto       // Muovi le righe verso l'alto di 50 pixel
      //   // opacity: 0,   // Parti da un'opacità di 0
      //   //stagger: 0.1, // Ritarda l'inizio dell'animazione per ogni riga di 0.1 secondi
      //   delay: 0.5,   // Ritarda l'inizio totale dell'animazione di 0.5 secondi
      //   });
      // });

      this.launch = true;
    }
  },
  beforeDestroy() {
    this.lenis.destroy();
  },
};
</script>

<style lang="scss">
.info {
  &__page {
    * {
      animation: fadeIn 300ms ease-in-out 200ms backwards;
    }

    background: linear-gradient(180deg, #a2a2a2 23.79%, #000000 51.86%);
    color: white;
    @include plain;
    min-height: 300vh;

    h3 {
      font-size: 36px;
      line-height: 100%;
      font-weight: normal;
      // line-height: 100%;
    }

    a {
      color: white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__container {
    max-width: 1800px;
    width: 90%;
    height: 100%;
    margin: auto;
    padding: 10vh 32px 150px 32px;
    display: flex;
    gap: 32px;

    @media screen and (max-width: 520px) {
      width: 100%;
      padding: 20px;
      padding-bottom: 150px;
      flex-direction: column;
    }
  }

  &__index {
    min-width: 150px;
    width: clamp(150px, 15%, 300px);
    padding-top: 20vh;

    @media screen and (max-width: 520px) {
      display: none;
    }
  }

  &__indexMob {
    display: none;
    margin-bottom: 52px;

    @media screen and (max-width: 520px) {
      display: block;
    }
  }

  &__titleMob {
    display: none;
    margin-bottom: 12px;

    @media screen and (max-width: 520px) {
      display: block;
    }
  }

  &__block {
    display: flex;
    margin-bottom: 100px;

    @media screen and (max-width: 520px) {
      flex-direction: column;
      margin-bottom: 52px;

      a {
        margin-top: 52px;
      }
    }

    &--alt {
      justify-content: flex-end;

      @media screen and (max-width: 520px) {
        flex-direction: column-reverse;
      }
    }
  }

  &__image {
    width: 50%;
    height: auto;
    align-self: flex-start;

    @media screen and (max-width: 520px) {
      width: 100%;
      margin-bottom: 32px;
    }
  }

  &__blockText {
    width: clamp(400px, 30%, 800px);
    padding: 0 32px;

    @media screen and (max-width: 520px) {
      width: 100%;
      padding: 0 0 32px 0;
    }

    h3 {
      margin-bottom: 32px;
    }

    h3:not(.info__titleMob) {
      @media screen and (max-width: 520px) {
        display: none;
      }
    }
  }
}

.hero {
  &__container {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 15vh;
  }

  &__text {
    margin-top: 20vh;
    width: 50%;
    z-index: 2;

    @media screen and (max-width: 520px) {
      width: 100%;
      margin-top: 35vh;
    }

    h3 {
      margin-bottom: 52px;
    }
  }

  &__contacs {
    display: flex;
    gap: 42px;

    @media screen and (max-width: 520px) {
      flex-direction: column-reverse;
      gap: 24px;
    }

    margin-bottom: 52px;
  }

  &__ref {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 520px) {
      gap: 4px;

      a {
        text-decoration: underline;
      }
    }
  }

  &__links {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 520px) {
      flex-direction: row;
      gap: 42px;

      a {
        text-decoration: underline;
      }
    }
  }

  &__img {
    max-width: 50%;
    max-height: 70vh;

    @media screen and (max-width: 520px) {
      display: none;
    }
  }

  &__imgAbs {
    position: absolute;
    // border: 1px solid red;
    z-index: 0;
    width: 70vw;
    height: auto;
    top: 82px;
    left: 15vw;

    @media screen and (min-width: 520px) {
      display: none;
    }
  }
}

.about {
  &__second {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    a {
      text-decoration: underline;
    }
  }

  &__highlight {
    margin: auto;
    padding: 0px 0;
    line-height: 140% !important;
    margin-top: clamp(120px, 30vh, 400px);
    margin-bottom: clamp(120px, 30vh, 400px);
    width: clamp(400px, 70%, 900px);

    @media screen and (max-width: 520px) {
      width: 100%;
      padding: 32px 0;
      margin-bottom: 52px;
    }

    .word {
      opacity: 0;
    }
  }
}

.disc {
  &__covers {
    display: grid;
    width: 50%;
    height: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 8px;

    @media screen and (max-width: 520px) {
      width: 100%;
      margin-bottom: 24px;
    }
  }

  &__coverBig {
    width: 100%;
    height: auto;
    grid-column: 1 / span 2;
    grid-row: 1 / 2 end;
  }

  &__third {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    a {
      text-decoration: underline;
      margin-top: 8px;
    }
  }
}

.trackCont {
  scroll-margin-top: 150px;
}

.content__headline .word {
  // color: red;
  display: inline-block;
  animation: wordSlide 300ms ease-out forwards;
  color: #00000000;
  // border: 1px solid green;
  // height: 20px;
  overflow: hidden;
  position: relative;

  &::after {
    animation: afterSlide 400ms ease-out forwards;
    //   background-color: white;

    content: attr(data-word);
    color: white;
    // height: 42px;
    // font-size: 36px;
    // width: 100%;
    // background: black;
    display: flex;
    position: absolute;
    animation-delay: calc(300ms + 100ms * var(--word-index));
    // top: 0;

    //   height: 150%;
    //   left: 0;
    //   position: absolute;
    //   animation-delay: var(--delay);
    //   // top: 100%;
    //   width: 100%;
    //   z-index: 2;
  }
}

.parScro .word {
  opacity: 0;
}

.inViewPar .word {
  // color: red;
  display: inline-block;
  animation: wordSlide 300ms ease-out forwards;
  color: #00000000;
  // border: 1px solid green;
  // height: 20px;
  overflow: hidden;
  position: relative;

  &::after {
    animation: afterSlide 150ms cubic-bezier(0, 0.13, 0, 1) forwards;
    //   background-color: white;

    content: attr(data-word);
    color: white;
    // height: 42px;
    // font-size: 36px;
    // width: 100%;
    // background: black;
    display: flex;
    position: absolute;
    animation-delay: calc(150ms + 50ms * var(--word-index));
    // top: 0;

    //   height: 150%;
    //   left: 0;
    //   position: absolute;
    //   animation-delay: var(--delay);
    //   // top: 100%;
    //   width: 100%;
    //   z-index: 2;
  }
}

.inViewHigh .word {
  // color: red;
  display: inline-block;
  animation: wordBlur 900ms cubic-bezier(0.12, 0.27, 0.11, 0.99) forwards;
  animation-delay: calc(40ms * var(--word-index));
  color: white;
  // border: 1px solid green;
  // height: 20px;
  // position: relative;

  &::after {
    // animation: afterSlideBlur 350ms cubic-bezier(0,.13,0,1) forwards;
    // //   background-color: white;

    // content: attr(data-word);
    // color: white;
    // // height: 42px;
    // // font-size: 36px;
    // // width: 100%;
    // // background: black;
    // display: flex;
    // position: absolute;
    // animation-delay: calc(350ms + 150ms * var(--word-index));
    // top: 0;

    //   height: 150%;
    //   left: 0;
    //   position: absolute;
    //   animation-delay: var(--delay);
    //   // top: 100%;
    //   width: 100%;
    //   z-index: 2;
  }
}

@keyframes wordSlide {
  0% {
    opacity: 0;
    // height: 0;
    // transform: translateY(26px);
  }

  100% {
    opacity: 1;
    // height: 42px;
    // transform: translate(0px);
  }
}

@keyframes afterSlide {
  0% {
    top: 100%;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes wordBlur {
  0% {
    opacity: 0;
    filter: blur(10px);
    transform: translateY(8px);
  }

  100% {
    opacity: 1;
  }
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

// .heroEnter-enter-active {
//   opacity: 0.5;
//   transition: all 3s cubic-bezier(1, 0.5, 0.8, 1);
// }

// .heroEnter-leave-active {
//   transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
// }

.hero__img {
  animation: testImg 2000ms cubic-bezier(0, 0.13, 0, 1) 500ms backwards;
}

@keyframes testImg {
  0% {
    opacity: 0;
    filter: blur(20px);
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.heroEnter-enter,
.heroEnter-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  img {
    // opacity: 0;
  }
}
</style>
