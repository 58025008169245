import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    selFilter: "All",
    homeWork: 0,
    fromHome: false,
    Opening: true,
    isCovering: false,
    isRevealing: false,
    isMobile: false,
  },
  getters: {},
  mutations: {
    pushFilter(state, tag) {
      state.selFilter = tag;
    },
    pushWork(state, index) {
      state.homeWork = index;
    },
    pushOrigin(state, bool) {
      state.fromHome = bool;
    },
    // startLoading(state) {
    //   state.isLoading = true;
    // },
    // finishLoading(state) {
    //   state.isLoading = false;
    // }
    setOpening(state, isOpening) {
      state.Opening = isOpening;
    },
    setCovering(state, isCovering) {
      state.isCovering = isCovering;
    },
    setRevealing(state, isRevealing) {
      state.isRevealing = isRevealing;
    },
    setMobile(state, isMobile) {
      state.isMobile = isMobile;
    },
  },
  actions: {},
  modules: {},
});
