import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import HomeView from "../views/HomeView.vue";
import WorksList from "../views/WorksList.vue";
import Work from "../views/SingleWork.vue";
import Info from "../views/InfoView.vue";
import split from "../views/splitTest.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/xxxc",
    name: "split",
    component: split,
  },
  {
    path: "/works",
    name: "works",
    component: WorksList,
  },
  {
    path: "/info",
    name: "info",
    component: Info,
  },
  {
    path: "/works/:id",
    name: "work",
    component: Work,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/*",
    name: "404",
    component: HomeView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (!store.state.Opening && !store.state.isMobile) {
    store.commit("setCovering", true);
    setTimeout(() => {
      store.commit("setCovering", false);
      next();
    }, 700); // Durata della transizione
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  if (!store.state.Opening && !store.state.isMobile) {
    store.commit("setRevealing", true);
    setTimeout(() => {
      store.commit("setRevealing", false);
    }, 700); // Durata della transizione
  }
});

// router.beforeEach((to, from, next) => {
//   store.commit('setLoading', true);
//   console.log("SUUUCUCCCAAA")
//   next();
// });

// router.afterEach((to, from) => {
//   store.commit('setLoading', false);
//   console.log("MANANANAANANAN")
// });

export default router;
