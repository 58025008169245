<template>
  <div class="caro__container" v-if="loading">
    <div ref="swiper" class="swiper">
      <div class="swiper-wrapper">
        <bars-work
          class="swiper-slide"
          :class="activeIndex === index && open && 'sw_select'"
          v-for="(w, index) in works"
          :key="index + 'homW'"
          :activeIndex="activeIndex"
          :index="index"
          :orderId="'bars_' + (index + 1)"
          :work="w.project.uid"
          @videoClick="caroEvent(index)"
          :open="index === activeIndex && open"
        />
      </div>
    </div>
    <div class="ctrl__container">
      <button class="ctrl__btn sw_next">
        <img src="../assets/img/Skip Back-1.svg" alt="" />
        <div class="ctrl__label">NEXT</div>
      </button>
      <button class="ctrl__btn sw_prev">
        <img src="../assets/img/Skip Back.svg" alt="" />
        <div class="ctrl__label">PREVIOUS</div>
      </button>
    </div>
  </div>
</template>

<script>
import Swiper, { Navigation, Pagination } from "swiper";
// import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
// import Swiper from "swiper";
// import styles bundle
import "swiper/swiper-bundle.min.css";
import "@mux/mux-player";
import BarsWork from "./BarComp/BarsWork.vue";

export default {
  components: { BarsWork },
  data() {
    return {
      loading: false,
      works: null,
      activeIndex: 0,
      slide: null,
      open: false,
    };
  },
  watch: {
    // loading(n, o) {
    //   if (n) {
    //   }
    // },
  },
  methods: {
    caroEvent(i) {
      if (i === this.activeIndex + 1 || i < this.activeIndex - 2)
        this.slide.slideNext(400, false);
      else if (i === this.activeIndex - 1 || i > this.activeIndex + 2)
        this.slide.slidePrev(400, false);
    },
    fuck() {
      this.open = false;
      // this.slide.slideNext(500, false);
      // this.slide.update();
    },
    slider() {
      const SECOND = 100; // milliseconds

      // init Swiper:
      this.slide = new Swiper(this.$refs.swiper, {
        // configure Swiper to use modules
        // modules: [Navigation, Pagination, Autoplay],
        modules: [Navigation, Pagination],
        centeredSlides: true,
        loopedSlides: 2,
        initialSlide: this.$store.state.homeWork,
        // visibilityFullFit: true,
        // loopPreventsSliding: false,
        slidesPerView: "auto",
        spaceBetween: window.innerWidth <= 520 ? 8 : 16,

        loop: true,
        // autoplay: {
        //   delay: 3 * SECOND,
        //   disableOnInteraction: false,
        // },
        speed: 2 * SECOND,
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true,
        // },
        navigation: {
          nextEl: ".sw_next",
          prevEl: ".sw_prev",
          // nextEl: ".swiper-button-next",
          // prevEl: ".swiper-button-prev",
        },

        // Events
        on: {
          slideChange: (swiper) => {
            this.$store.commit("pushWork", swiper.realIndex);
            console.log(swiper.realIndex);
            this.activeIndex = swiper.realIndex;
          },
          // init: function () {
          //   console.log("swiper initialized");
          // },
          //   slideChange: function () {
          //   console.log("PROVA")
          //   this.update();
          // },
          transitionEnd: function () {
            // console.log("PROVA");
            this.update();
          },
          // beforeLoopFix: function () {
          //   console.log("loop fix");
          // },
        },
      });
    },
    async getContent() {
      const response = await this.$prismic.client.getSingle("home");
      this.works = response.data.home_projects;
      this.loading = true;
      this.slider();
    },
  },
  updated() {},
  created() {
    this.getContent();
  },
  mounted() {
    this.$nextTick(function () {
      this.getContent();
      if (this.loading) {
        // this.slider();
      }
    });
  },
};
</script>
<style lang="scss">
.caro__container {
  display: grid;
  width: 100%;
  min-height: 600px;
  height: 80vh;
  // background: peachpuff;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
  @media screen and (max-width: 520px) {
    height: calc(85vh - 250px);
    height: calc(85dvh - 250px);
    grid-template-rows: 90% 52px;
  }
}
.swiper {
  max-width: 100%;
  /* height: 75vh; */
  width: 100%;
  height: 100%;
  /* background: red; */
}

.swiper-wrapper {
  align-items: flex-end;
}

.swiper-slide {
  /* background: moccasin; */
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  // z-index: 200;
  /* height: 50%; */
  /* transition: all 400ms ease-in-out; */
  @media screen and (max-width: 520px) {
    width: 82vw;
  }
}

.sw_select {
  width: 100%;
}

.ctrl {
  &__container {
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: flex-start;
    top: 20vh;
    left: 16px;
    gap: 16px;
    z-index: 200;
    transform: scale(1);
    @media screen and (max-width: 520px) {
      position: static;
      grid-row: 2;
      flex-direction: row-reverse;
      padding: 12px 9%;
    }
  }

  &__label {
    @include plain;
    font-size: 16px;
    color: white;
    background: black;
    display: flex;
    border-radius: 20px;
    padding: 6px 12px;
    line-height: 100%;
    display: none;
    opacity: 0;
  }

  &__btn {
    display: flex;
    height: 32px;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    cursor: pointer;

    gap: 8px;
    @media (min-width: 520px) and (hover: hover) {
      &:hover {
        .ctrl__label {
          display: flex;
          animation: labelEnter 500ms ease-out forwards;
        }
      }
    }
    @media screen and (max-width: 520px) {
      height: 32px;
      width: 32px;
      padding: 0;
      img {
        height: 28px;
        width: 28px;
      }
    }
  }
}

@keyframes labelEnter {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
  }
}
</style>
