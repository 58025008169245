<template>
  <div class="home">
    <transition name="fadeHome">
      <bar-carousel />
    </transition>
  </div>
</template>

<script>
// import BarCarousel from "@/components/BarCarousel.vue";
import BarCarousel from "@/components/BarCarouselNew.vue";
export default {
  components: { BarCarousel },
  name: "HomeView",
};
</script>

<style lang="scss" scoped>
.home {
  min-height: 100vh;
  padding-top: 15vh;
  @media screen and (max-width: 520px) {
    padding-top: clamp(100px, 20vh, 400px);
  }
}

.fadeHome-enter-active {
  transition: all 1s cubic-bezier(1, 0.5, 0.8, 1);
}

.fadeHome-leave-active {
  // transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.fadeHome-enter,

/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  filter: blur(20px);
}
.fadeHome-leave-to {
}
</style>
