<template>
  <div class="opening__container splitting" data-splitting="chars">
    <div class="opening__title">HENFORD</div>
  </div>
</template>

<script>
import Splitting from "splitting";
export default {
  mounted() {
    document.body.style.overflow = "hidden";
    Splitting();
    const c = document.querySelectorAll(".char");
    c.forEach((x) => {
      let rand = Math.round(Math.random() * 600 + 300);
      x.style.setProperty("--delay", rand + "ms");
    });
  },
  beforeDestroy() {
    document.body.style.overflow = "auto";
  },
};
</script>

<style lang="scss">
.opening__container {
  z-index: 200;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: hsla(288, 0%, 100%, 1);
  background-color: black;
  // background-image:
  //   radial-gradient(at 37% 91%, hsla(160, 0%, 45%, 1) 0px, transparent 50%),
  //   radial-gradient(at 61% 68%, hsla(74, 0%, 45%, 1) 0px, transparent 50%),
  //   radial-gradient(at 79% 39%, hsla(334, 0%, 45%, 1) 0px, transparent 50%),
  //   radial-gradient(at 95% 9%, hsla(239, 0%, 45%, 1) 0px, transparent 50%),
  //   radial-gradient(at 17% 19%, hsla(293, 0%, 45%, 1) 0px, transparent 50%),
  //   radial-gradient(at 33% 86%, hsla(116, 0%, 45%, 1) 0px, transparent 50%);

  //   url(https://grainy-gradients.vercel.app/noise.svg);
  // filter: contrast(1070%) brightness(100%);
  // animation: gradientMove 600ms linear infinite alternate;
}

.opening__title {
  @include helve;
  font-weight: normal;
  font-size: 8vw;
  line-height: 100%;
  overflow: hidden;
  position: relative;
  // background: red;
  // filter: blur(600px);

  .char {
    // color: red;
    display: inline-block;
    color: black;
    color: white;
    // border: 1px solid green;
    // height: 20px;
    // animation: afterSlide 6000ms cubic-bezier(0, 0.13, 0, 1) forwards;
    animation: charSlide 700ms cubic-bezier(0.16, 0.47, 0.49, 1) backwards;
    animation-delay: var(--delay);

    // &::after {
    //   animation: afterSlide 150ms cubic-bezier(0, 0.13, 0, 1) forwards;
    //   //   background-color: white;

    //   content: attr(data-word);
    //   color: red;
    //   // height: 42px;
    //   // font-size: 36px;
    //   // width: 100%;
    //   // background: black;
    //   display: flex;
    //   position: absolute;
    //   animation-delay: calc(150ms + 50ms * var(--word-index));
    //   // top: 0;
    // }
  }
}

@keyframes charSlide {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes blurGrain {
  0% {
    filter: blur(0);
  }

  100% {
    filter: blur(0px);
  }
}

@keyframes gradientMove {
  0% {
    background-image: radial-gradient(
        at 37% 91%,
        hsla(160, 0%, 45%, 1) 0px,
        transparent 50%
      ),
      radial-gradient(at 61% 68%, hsla(74, 0%, 45%, 1) 0px, transparent 50%),
      radial-gradient(at 79% 39%, hsla(334, 0%, 45%, 1) 0px, transparent 50%),
      radial-gradient(at 95% 9%, hsla(239, 0%, 45%, 1) 0px, transparent 50%),
      radial-gradient(at 17% 19%, hsla(293, 0%, 45%, 1) 0px, transparent 50%),
      radial-gradient(at 33% 86%, hsla(116, 0%, 45%, 1) 0px, transparent 50%);
  }

  100% {
    background-image: radial-gradient(
        at 100% 51%,
        hsla(160, 0%, 45%, 1) 0px,
        transparent 50%
      ),
      radial-gradient(at 31% 58%, hsla(74, 0%, 45%, 1) 0px, transparent 50%),
      radial-gradient(at 21% 10%, hsla(334, 0%, 45%, 1) 0px, transparent 50%),
      radial-gradient(at 80% 50%, hsla(239, 0%, 45%, 1) 0px, transparent 50%),
      radial-gradient(at 0% 82%, hsla(293, 0%, 45%, 1) 0px, transparent 50%),
      radial-gradient(at 76% 10%, hsla(116, 0%, 45%, 1) 0px, transparent 50%);
  }
}
</style>
