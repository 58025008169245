<template>
  <div
    class="prj__cont"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
  >
    <router-link :to="`/works/${prj.uid}`" v-if="loading">
      <div
        class="prj__img"
        :style="`background-image:url(${hovered ? imgGifC : imgStaticC})`"
        alt=""
      ></div>
      <!-- <div
        class="prj__img"
        :style="`background-image:url(${hovered ? imgGif : imgStatic})`"
        alt=""
      ></div> -->
      <div class="prj__text">
        <h3>{{ titleSplit[0] }}</h3>
        <h3>{{ titleSplit[1] }}</h3>
        <!-- <h3>{{ prjData.data.title }}</h3> -->
        <p v-if="hovered">{{ prjData.data.subtitle }}</p>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    prj: Object,
  },
  data() {
    return {
      hovered: false,
      prjData: null,
      imgStatic:
        "https://image.mux.com/dlGP5KV01GNe9MHRUFVFhpbMuUDeYv301CUnpcwUGEar00/thumbnail.png?time=0",
      imgGif:
        "https://image.mux.com/dlGP5KV01GNe9MHRUFVFhpbMuUDeYv301CUnpcwUGEar00/animated.gif??width=320&start=1",
      loading: false,
    };
  },
  computed: {
    titleSplit() {
      return this.prjData ? this.prjData.data.title.split("-") : "";
    },
    imgStaticC() {
      return `https://image.mux.com/${this.prjData.data.id_video}/thumbnail.png?time=${this.prjData.data.image_preview}`;
    },
    imgGifC() {
      return `https://image.mux.com/${
        this.prjData.data.id_video
      }/animated.gif??width=320&start=${this.prjData.data.gif_preview || 0}`;
    },
  },
  methods: {
    async getContent() {
      // Query the API and assign the response to "response"
      // const response = await this.$prismic.client.query("");
      const response = await this.$prismic.client.getByUID(
        "project",
        this.prj.uid
      );
      // console.log(this.prj.uid)
      // console.log(response)
      this.prjData = response;
      this.loading = true;
    },
  },
  mounted() {
    // Call the API query method
    this.getContent();
  },
};
</script>

<style lang="scss" scoped>
.prj {
  &__cont {
    a {
      transition: all 400ms ease-out;
      height: 120px;
      display: flex;
      align-items: center;
      gap: 20px;

      @media (hover: hover) {
        &:hover {
          height: 252px;
        }
      }
    }

    @media screen and (max-width: 520px) {
      a {
        gap: 8px;
        height: 80px;
      }
    }
  }

  &__img {
    height: 100%;
    aspect-ratio: 16/9;
    border-radius: 4px;
    background-size: cover;
    background-position: center;
    //  background-color: linear-gradient(180deg, #f2f1f1 0%, rgba(227, 227, 227, 0.19) 100%);
    //  background-color: -webkit-gradient(180deg, #f2f1f1 0%, rgba(227, 227, 227, 0.19) 100%);
    //  background-color: -webkit-linear-gradient(180deg, #f2f1f1 0%, rgba(227, 227, 227, 0.19) 100%);
    // background-color: linear-gradient(180deg, #EBEBEB 0%, #F9F9F9 100%);
    // background: linear-gradient(45deg, blue, red);
    background-color: #f2f1f1;
  }

  &__text {
    @include plain;

    h3 {
      font-size: 28px;
      line-height: 120%;
      @media screen and (max-width: 520px) {
        font-size: 16px;
      }
    }
  }
}
</style>
