<template>
  <div class="contact__comp" :class="dark && 'contact__comp--dark'">
    <h3>GET IN <br />TOUCH</h3>
    <div class="contact__container" :class="dark && 'contact__container--dark'">
      <div class="contact__ref">
        <a
          class="mail"
          href="#mailgo"
          data-address="henfordmusic"
          data-domain="gmail.com"
          >henfordmusic@gmail.com</a
        >
        <a href="#mailgo" data-tel="+393480569791">+39 3480569791</a>
        <a href="https://goo.gl/maps/x9VxTxTxMKEkSGTDA"
          >Indiehub Studios, <br />
          Via Bramante 39, 20154 <br />
          Milan, Italy</a
        >
      </div>
      <div class="contact__link">
        <a href="https://vimeo.com/henford">Vimeo</a>
        <a href="https://www.instagram.com/henford_">Instagram</a>
        <a
          href="https://open.spotify.com/artist/5QVASz3RXDVaWbWvQplK6c?si=9LgeUuVfTPu3Bg7-sWMR5g"
          >Spotify</a
        >
        <a
          v-if="info"
          class="contact__link--badge"
          href="https://open.spotify.com/artist/5QVASz3RXDVaWbWvQplK6c?si=9LgeUuVfTPu3Bg7-sWMR5g"
          ><img src="../assets/img/spotify.svg" alt=""
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dark: Boolean,
    info: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.contact {
  &__comp {
    h3 {
      @include helve;
      font-size: 90px;
      line-height: 100%;
      color: black;
      margin-bottom: 24px;
    }
    &--dark {
      h3 {
        color: white;
      }
    }
  }

  &__container {
    @include plain;
    color: black;
    display: flex;
    gap: 42px;
    &--dark {
      a {
        color: white;
      }
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__ref {
    display: flex;
    flex-direction: column;

    & a:last-child {
      padding-top: 18px;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    &--badge {
      margin-top: 24px;
    }
  }
}
</style>
