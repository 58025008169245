<template>
  <div class="work__container" v-if="loading">
    <div class="nav__cont">
      <router-link class="nav__title" to="/">HENFORD</router-link>
      <router-link
        class="nav__close"
        :to="$store.state.fromHome ? '/' : '/works'"
      >
        <img src="../assets/img/Close.svg" alt="" />
      </router-link>
    </div>
    <div class="work__vidCont">
      <mux-player
        ref="videoRef"
        class="vid"
        theme="minimal"
        style="--play-button: block; --center-controls: block"
        stream-type="on-demand"
        :playback-id="work.data.id_video"
        primary-color="#FFFFFF"
        secondary-color="#292929aa"
        disable-cookies="true"
        :poster="`https://image.mux.com/${work.data.id_video}/thumbnail.png?time=${work.data.image_preview}`"
      >
      </mux-player>
    </div>
    <div class="work__colo">
      <div class="work__title">
        <h3>{{ work.data.title }}</h3>
        <p>{{ work.data.subtitle }}</p>
      </div>
      <bars-wave
        v-if="browser === 'Chrome' || browser === 'Firefox'"
        :sound="isPlay"
        class="work__wave"
      />
      <bars-wave-static
        v-else
        :sound="isPlay"
        :work="true"
        class="work__wave"
      />
      <div class="work__credits">
        <ul>
          <li v-for="(c, index) in work.data.credits" :key="index + 'cred'">
            {{ c.role }}: {{ c.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import "@mux/mux-player";
import "@mux/mux-player/themes/minimal";
import BarsWaveStatic from "@/components/BarsWave.vue";
import BarsWave from "@/components/BarsWaveReactive.vue";
export default {
  components: { BarsWave, BarsWaveStatic },
  data() {
    return {
      work: null,
      loading: false,
      videoElement: null,
      isPlay: false,
      browser: null,
    };
  },
  methods: {
    async getContent() {
      const response = await this.$prismic.client.getByUID(
        "project",
        this.$route.params.id
      );
      this.work = response;
      this.loading = true;
    },
    getBrowser() {
      let userAgent = navigator.userAgent;
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return (this.browser = "iOS");
      } else if (/Android/.test(userAgent)) {
        return (this.browser = "Android");
      } else if (/Chrome/.test(userAgent)) {
        return (this.browser = "Chrome");
      } else if (/Safari/.test(userAgent)) {
        return (this.browser = "Safari");
      } else if (/Firefox/.test(userAgent)) {
        return (this.browser = "Firefox");
      } else if (/MSIE|Trident/.test(userAgent)) {
        return (this.browser = "IE");
      } else {
        return (this.browser = "Other");
      }
    },
  },
  created() {
    this.getContent();
  },
  mounted() {
    this.getBrowser();
  },
  updated() {
    const muxPlayer = document.querySelector("mux-player").shadowRoot;
    this.videoElement = muxPlayer
      .querySelector("media-theme")
      .querySelector("mux-video")
      .shadowRoot.querySelector("video");
    this.videoElement.addEventListener("playing", (event) => {
      this.isPlay = true;
    });

    this.videoElement.addEventListener("pause", (event) => {
      this.isPlay = false;
    });
  },
  beforeDestroy() {
    this.$store.commit("pushOrigin", false);
  },
};
</script>

<style lang="scss" scoped>
.work {
  &__container {
    min-height: 100vh;
    width: 100%;
    @media screen and (max-width: 520px) {
      min-height: calc(100vh - 66px - 78px);
      min-height: calc(100dvh - 66px - 78px);
    }
  }

  &__vidCont {
    width: 100%;
    height: 75vh;
    background: black;

    @media screen and (max-width: 520px) {
      height: auto;
    }

    .vid {
      height: 100%;
    }
  }

  &__colo {
    display: flex;
    flex-direction: row;
    padding: 24px;
    gap: 32px;
    justify-content: space-between;
    // align-items: flex-start;

    div {
      // flex-grow: 1;
      // flex-shrink: 1;
      // background: lightsalmon;
      width: 100%;
    }

    @media screen and (max-width: 520px) {
      flex-direction: column;
      width: 100%;
      padding: 20px;
      padding-bottom: 150px;
      gap: 24px;

      // background: palevioletred;
      div {
        max-width: 100%;
      }
    }
  }

  &__title {
    max-width: 25%;
    margin-top: 0;
    margin-top: 12px;
    @include plain;
    margin-bottom: 56px;

    p {
      font-size: 16px;
    }

    h3 {
      padding-top: 0;
      font-size: 22px;
      margin-bottom: 8px;
    }

    @media screen and (max-width: 520px) {
      margin-bottom: 0px;
    }
  }

  &__credits {
    max-width: 25%;

    ul {
      @include plain;
      font-size: 16px;
      list-style-type: none;

      @media screen and (max-width: 520px) {
        padding-inline-start: 0px;
      }
    }
  }

  &__wave {
    max-width: 40%;

    // margin-top: 42px;
    @media screen and (max-width: 520px) {
      margin-top: 0;
    }
  }
}

.nav {
  &__cont {
    position: fixed;

    top: 0;
    left: 0;
    width: 100%;
    // background: #fff5;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    z-index: 30;
    color: white;
    @include helve;
    padding: 20px;

    a {
      background: none;
      outline: none;
      border: none;
      cursor: pointer;
      color: whitesmoke;
      text-decoration: none;
    }

    @media screen and (max-width: 520px) {
      padding: 20px;
      align-items: center;
      position: sticky;

      a {
        color: black;

        img {
          filter: brightness(0);
        }
      }
    }
  }

  &__title {
    padding: 0;
    font-size: 4.5vw;
    text-decoration: none;
    line-height: 100%;

    @media screen and (max-width: 520px) {
      font-size: 38px;
    }
  }
}

mux-player {
  --media-object-fit: contain;
  --play-button: block;
  // --bottom-play-button: none;
  // --live-button: none;
  // --seek-backward-button: none;
  // --seek-forward-button: none;
  // --mute-button: none;
  // --captions-button: none;
  // --airplay-button: none;
  // --pip-button: none;
  // --fullscreen-button: none;
  // --cast-button: none;
  // --playback-rate-button: none;
  // --volume-range: none;
  // --time-range: none;
  // --time-display: none;
  // --duration-display: none;
  // --controls: none;
}
</style>
