<template>
  <div class="anchor__comp">
    <div class="anchor__cont">
      <div class="anchor__list">
        <button class="anchor__tag" @click="anch('about')">
          <div
            class="anchor__select"
            :class="anchor === 'about' && 'anchor__select--selected'"
          ></div>
          <div class="anchor__label">About</div>
        </button>
        <button class="anchor__tag" @click="anch('disc')">
          <div
            class="anchor__select"
            :class="anchor === 'disc' && 'anchor__select--selected'"
          ></div>
          <div class="anchor__label">Discography</div>
        </button>
        <button class="anchor__tag" @click="anch('contacts')">
          <div
            class="anchor__select"
            :class="anchor === 'contacts' && 'anchor__select--selected'"
          ></div>
          <div class="anchor__label">Contacts</div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    anchor: String,
  },
  methods: {
    anch(k) {
      this.$emit("toanch", k);
    },
  },
};
</script>

<style lang="scss" scoped>
.anchor {
  &__comp {
    // width: 100%;
  }
  &__cont {
    @include plain;
    color: white;
    position: sticky;
    top: 32px;

    h3 {
      font-size: 22px;
      margin-bottom: 32px;
    }
  }

  &__tag {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    color: white;
    cursor: pointer;
    &:hover {
      .anchor__select:not(.anchor__select--selected) {
        margin-right: 8px;
        width: 12px;
      }
    }
  }

  &__select {
    height: 3px;
    width: 0;
    margin-right: 0px;
    background: white;
    transition: all 300ms ease-in-out;

    &--selected {
      margin-right: 8px;
      width: 32px;
    }
  }
}
</style>
