<template>
  <div class="filter__comp">
    <div class="filter__cont">
      <!-- <h3>Filter</h3> -->
      <div class="filter__list">
        <div
          class="filter__tag"
          v-for="t in tags"
          :key="t"
          @click="filterSelect(t)"
        >
          <div
            class="filter__select"
            :class="sel === t && 'filter__select--selected'"
          ></div>
          <div class="filter__label">{{ t }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tags: Array,
  },
  data() {
    return {
      select: "All",
    };
  },
  computed: {
    sel() {
      return this.$store.state.selFilter;
    },
  },
  methods: {
    filterSelect(k) {
      this.$store.commit("pushFilter", k);
      this.$emit("close-fil");
      window.scrollTo({ top: 0, behavior: "smooth" });
      // document.body.scrollTop = 0; // For Safari
      // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      // this.$store.commit("pushHome", response.results)
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  &__comp {
    width: 100%;
    height: 100%;
  }
  &__cont {
    @include plain;
    color: black;
    position: sticky;
    top: 98px;
    @media screen and (max-width: 520px) {
      position: relative;
      top: 0;
    }

    h3 {
      font-size: 22px;
      margin-bottom: 32px;
    }
  }

  &__tag {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      .filter__select:not(.filter__select--selected) {
        width: 8px;
        margin-right: 8px;
      }
    }
  }

  &__select {
    height: 3px;
    width: 0;
    margin-right: 0px;
    background: black;
    transition: all 300ms ease-in-out;

    &--selected {
      margin-right: 8px;
      width: 32px;
      @media screen and (max-width: 520px) {
        width: 20px;
      }
    }
  }
}
</style>
