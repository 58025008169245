<template>
  <div class="bars__container" :class="open && 'bars__container--open'">
    <div class="nav__cont" v-if="open">
      <button class="nav__title" @click="closeVid">HENFORD</button>
      <button class="nav__close" @click="closeVid">
        <img src="../../assets/img/Close.svg" alt="" />
      </button>
    </div>
    <div class="bars__placeholder"></div>
    <div
      v-if="loading"
      class="video__container"
      :id="orderId"
      :class="classVideo"
      :style="{ backgroundImage: `url(${imgStaticC})` }"
    >
      <transition name="videoFade">
        <mux-player
          v-if="index === activeIndex && preview"
          ref="videoRef"
          class="vid"
          muted
          autoplay
          stream-type="on-demand"
          disable-cookies="true"
          :playback-id="workData.data.id_video"
          primary-color="#FFFFFF"
          secondary-color="#292929"
          @ended="closePreview()"
          :poster="imgStaticC"
        >
        </mux-player>
      </transition>
      <div class="video__cursorCont" v-if="!open" @click="videoClick()">
        <div
          v-if="index === activeIndex - 1 || index > activeIndex + 2"
          class="video__cursor video__cursor--desk"
          :id="'cursor_' + orderId"
        >
          PREVIOUS
        </div>
        <div
          v-else-if="index === activeIndex + 1 || index < activeIndex - 2"
          class="video__cursor video__cursor--desk"
          :id="'cursor_' + orderId"
        >
          NEXT
        </div>
        <div
          v-else
          class="video__cursor video__cursor--desk"
          :style="open && 'display:none'"
          :id="'cursor_' + orderId"
        >
          LISTEN <img src="../../assets/img/play.svg" />
        </div>
        <div
          v-show="index === activeIndex"
          class="video__cursor video__cursor--mob"
          :style="open && 'display:none'"
        >
          LISTEN <img src="../../assets/img/play.svg" />
        </div>
      </div>
    </div>
    <div
      class="content__container"
      :class="[
        open && 'content__container--open',
        index !== activeIndex && 'content__container--side',
      ]"
      v-if="loading"
    >
      <div class="content__title">
        <h3>{{ workData.data.title }}</h3>
        <p v-if="open">{{ workData.data.subtitle }}</p>
      </div>
      <div v-if="open" class="content__credits">
        <ul>
          <li v-for="(c, index) in workData.data.credits" :key="index + 'cred'">
            {{ c.role }}: {{ c.name }}
          </li>
        </ul>
      </div>
      <bars-wave :sound="index === activeIndex" class="content__wave" />
    </div>
  </div>
</template>

<script>
// import BarContent from '../BarComp/BarContent.vue'
// import BarVideo from '../BarComp/BarVideo.vue'
import "@mux/mux-player";
import BarsWave from "../BarsWave.vue";
export default {
  components: { BarsWave },
  // components: { BarVideo, BarContent },
  props: {
    work: String,
    index: Number,
    activeIndex: Number,
    orderId: String,
    open: Boolean,
  },
  data() {
    return {
      element: null,
      workData: null,
      loading: false,
      video: null,
      preview: true,
      // open: false,
      up: false,
      videoElement: null,
    };
  },
  watch: {
    activeIndex(n, o) {
      if (n !== this.index && o === this.index) {
        this.preview = true;
        const muxPlayer = document.querySelector("mux-player").shadowRoot;
        this.videoElement = muxPlayer
          .querySelector("media-theme")
          .querySelector("mux-video")
          .shadowRoot.querySelector("video");
        // this.videoElement.style.border = "16px solid red";
        this.videoElement.style.opacity = ".5";
      }
    },
  },
  computed: {
    imgStaticC() {
      return `https://image.mux.com/${this.workData.data.id_video}/thumbnail.png?time=${this.workData.data.image_preview}`;
    },
    classVideo() {
      if (this.index < this.activeIndex) return "video__container--left";
      else if (this.index > this.activeIndex) return "video__container--right";
      else return "video__container--active";
    },
    // focusCard(){
    //   if(this.index === this.activeIndex) return true
    //   else false
    // }
  },
  methods: {
    closeVid() {
      this.$parent.open = false;
      // this.$emit("closeVid");
    },
    closePreview() {
      this.preview = false;
    },
    videoClick() {
      if (this.index === this.activeIndex) {
        this.$store.commit("pushWork", this.index);
        this.$store.commit("pushOrigin", true);
        this.$router.push(`/works/${this.work}`);
      } else this.$emit("videoClick");
    },
    async getContent() {
      // Query the API and assign the response to "response"
      // const response = await this.$prismic.client.query("");
      const response = await this.$prismic.client.getByUID(
        "project",
        this.work
      );
      // console.log(this.prj.uid)
      // console.log(response)
      this.workData = response;
      this.loading = true;
      // this.dlyBar();
    },
    moveMouse(event) {
      const textContainer = document.querySelector("#cursor_" + this.orderId);
      document.body.style.cursor = "none";
      let left = event.offsetX;
      let top = event.offsetY;

      // Aggiorna la posizione del div contenente il testo
      // textContainer.style.left = event.clientX + 'px';
      // textContainer.style.top = event.clientY + 'px';
      textContainer.style.left = left + "px";
      textContainer.style.top = top + "px";
    },
    leaveMouse(event) {
      document.body.style.cursor = "default";
    },
    setBar() {
      // this.$emit("countGif");
      var lh = 30 + Math.random() * 30;
      var rh = 50 + Math.random() * 30;
      this.element = document.querySelector("#" + this.orderId);
      this.element.style.setProperty("--lheight", lh + "%");
      this.element.style.setProperty("--rheight", rh + "%");

      //cursor

      this.element.addEventListener("mousemove", this.moveMouse);

      this.element.addEventListener("mouseleave", this.leaveMouse);
    },
  },
  created() {
    this.getContent();
  },
  updated() {
    if (!this.up) {
      this.setBar();
      this.up = true;
    }
  },
  beforeDestroy() {
    this.element.removeEventListener("mousemove", this.moveMouse);
    this.element.removeEventListener("mouseleave", this.leaveMouse);
    document.body.style.cursor = "auto";
  },
};
</script>

<style lang="scss" scoped>
.bars {
  &__container {
    display: grid;
    // height: 100%;
    // width: 100%;
    grid-template-columns: 100%;
    grid-template-rows: 5% 75% 20%;

    &--open {
      grid-template-rows: 0% 70% 30%;

      .video__container {
        border-radius: 0;
        z-index: 25;
      }
    }
  }

  &__placeholder {
    width: 100%;
    height: 100%;
    z-index: 0;
    // background: aliceblue;
  }
}

video {
  border-radius: 16px !important;
  overflow: hidden;
}

.video {
  &__container {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-color: grey;
    border-radius: 16px;
    height: 50%;
    align-self: self-end;
    transition: all 300ms ease-in-out;
    transform: scale(1);

    &--active {
      // border: 5px solid blueviolet;
      height: 100%;
      overflow: hidden;
    }

    &--left {
      height: var(--lheight);
      z-index: 10;
      &:hover {
        height: calc(var(--lheight) + 10%);
      }
      // opacity: 50%;
    }

    &--right {
      height: var(--rheight);
      z-index: 10;
      &:hover {
        height: calc(var(--rheight) + 10%);
      }
      // opacity: 50%;
    }

    &:hover .video__cursor {
      opacity: 1;
    }
  }

  &__cursorCont {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__cursor {
    @include plain;
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    /* Per non intercettare gli eventi del mouse */
    opacity: 0;
    transition: opacity 0.2s ease-out;
    color: white;
    // mix-blend-mode: difference;

    img {
      width: 24px;
      height: 24px;
      position: absolute;
      top: -38px;
      left: calc(50% - 12px);
      // mix-blend-mode: normal;
    }

    &--mob {
      display: none;
    }

    @media screen and (max-width: 520px) {
      &--desk {
        display: none;
      }

      &--mob {
        display: block;
        opacity: 1;
        transform: translate(-50%, calc(-50% + 20px));
      }
    }

    // -webkit-text-stroke: 1px black;
  }
}

.content {
  &__container {
    align-self: self-start;
    display: flex;
    position: relative;
    align-content: flex-start;
    color: black;
    // flex-direction: column;
    // height: 16vh;
    &--side {
      opacity: 0.4;
    }

    &--open {
      justify-content: space-between;
      // align-content: ;
      padding: 32px;

      .content__title {
        margin-top: 0;
        // max-width: 25%;
        width: 28%;
        margin-top: 0;

        h3 {
          padding-top: 0;
          font-size: 34px;
          margin-bottom: 8px;
        }
      }

      .content__credits {
        max-width: 28%;
        width: 28%;
      }

      .content__wave {
        position: absolute;
        top: 42px;
        left: 33%;
        width: 34%;
      }
    }
  }

  &__title {
    margin-top: 12px;
    @include plain;
    font-size: 16px;
    margin-bottom: 24px;

    h3 {
      font-size: 16px;
      letter-spacing: -0.01em;
      line-height: 120%;
      font-size: normal;
    }

    p {
      font-size: 16px;
    }
  }

  &__credits {
    ul {
      @include plain;
      font-size: 16px;
      list-style-type: none;
    }
  }

  &__wave {
    position: absolute;
    top: 62px;
    left: 0;
    width: 100%;
  }
}

.nav {
  &__cont {
    position: fixed;

    top: 0;
    left: 0;
    width: 100vw;
    // background: #fff5;
    display: flex;
    justify-content: space-between;
    z-index: 30;
    color: white;
    @include helve;
    padding: 28px;

    button {
      background: none;
      outline: none;
      border: none;
      cursor: pointer;
      color: whitesmoke;
    }
  }

  &__title {
    padding: 0;
    font-size: 4.5vw;
    text-decoration: none;
    line-height: 100%;
  }
}

mux-player {
  height: 100%;
  --media-object-fit: cover;
  --play-button: none;
  --bottom-play-button: none;
  --live-button: none;
  --seek-backward-button: none;
  --seek-forward-button: none;
  --mute-button: none;
  --captions-button: none;
  --airplay-button: none;
  --pip-button: none;
  --fullscreen-button: none;
  --cast-button: none;
  --playback-rate-button: none;
  --volume-range: none;
  --time-range: none;
  --time-display: none;
  --duration-display: none;
  --controls: none;
}

.videoFade-enter-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.videoFade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.videoFade-enter,
.videoFade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  filter: blur(20px);
}
</style>
