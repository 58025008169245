import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PrismicVue from "@prismicio/vue";
import mailgo from "mailgo";

const endpoint = "https://henford.cdn.prismic.io/api/v2";

const mailgoConfig = {
  dark: true,
};

mailgo(mailgoConfig);

Vue.use(PrismicVue, {
  endpoint,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
