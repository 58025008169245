<template>
  <div class="wave__container">
    <canvas ref="canvasRef" width="600" height="150"></canvas>
  </div>
</template>

<script>
export default {
  props: {
    sound: Boolean,
  },
  data() {
    return {
      audioContext: null,
      analyser: null,
      dataArray: null,
      videoElement: null,
      canvasElement: null,
      canvasContext: null,
      pixelRatio: window.devicePixelRatio || 1,
      audioVisualizerSetupDone: false,
      item: null,
      browser: null,
    };
  },
  watch: {
    sound(s) {
      if (s === true) this.audioContext.resume();
    },
  },
  methods: {
    setupAudioVisualizer() {
      if (this.audioContext === null) {
        this.audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        this.analyser = this.audioContext.createAnalyser();
        this.analyser.fftSize = 128;
        this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
        this.item = this.audioContext.createMediaElementSource(
          this.videoElement
        );
        this.item.connect(this.analyser);
        this.analyser.connect(this.audioContext.destination);
        this.visualize();
      }
    },
    visualize() {
      const draw = () => {
        requestAnimationFrame(draw);
        let barHeight;
        if (this.dataArray) {
          this.analyser.getByteFrequencyData(this.dataArray);
        }

        this.canvasContext.clearRect(
          0,
          0,
          this.canvasElement.width * this.pixelRatio,
          this.canvasElement.height * this.pixelRatio
        );
        const barWidth = 2;

        let x = 0;

        const nBar = 54;
        let alphaWidth =
          (this.videoElement.currentTime / this.videoElement.duration) *
          (this.canvasElement.width / this.pixelRatio);

        for (let i = 0; i < nBar; i++) {
          barHeight = this.dataArray
            ? (this.dataArray[i] *
                ((this.canvasElement.height - 20) / this.pixelRatio)) /
                255 +
              2
            : 2;

          this.canvasContext.fillStyle = "rgb(0,0,0)";
          this.canvasContext.fillRect(
            x,
            (this.canvasElement.height / this.pixelRatio - barHeight) / 2,
            barWidth,
            barHeight
          );
          x +=
            barWidth +
            (this.canvasElement.width / this.pixelRatio - barWidth * nBar) /
              (nBar - 1);
        }
        this.canvasContext.fillStyle = "rgba(255,255,255, 0.7)";
        this.canvasContext.fillRect(
          alphaWidth,
          0,
          this.canvasElement.width - alphaWidth,
          this.canvasElement.height
        );
      };
      draw();
    },
  },
  mounted() {
    const muxPlayer = document.querySelector("mux-player").shadowRoot;
    this.videoElement = muxPlayer
      .querySelector("media-theme")
      .querySelector("mux-video")
      .shadowRoot.querySelector("video");

    this.canvasElement = this.$refs.canvasRef;
    this.canvasContext = this.canvasElement.getContext("2d");

    this.canvasElement.width = this.canvasElement.width * this.pixelRatio;
    this.canvasElement.height = this.canvasElement.height * this.pixelRatio;
    this.canvasContext.scale(this.pixelRatio, this.pixelRatio);
    this.setupAudioVisualizer();
  },
  beforeDestroy() {
    this.audioContext.close();
    this.audioContext = null;
  },
};
</script>

<style lang="scss" scoped>
.wave {
  &__container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    height: 42px;
    align-items: center;
  }

  &__bar {
    height: 2px;
    width: 2px;
    background: black;

    // border-radius: 8px;
    &--sound {
      animation: sound 0 - 800ms ease-in-out infinite alternate;
    }
  }
}

canvas {
  aspect-ratio: auto;
  width: 600px;
  // height: 100px;
  // border: 1px solid red;
  margin: auto;
}

// canvas {
//   width: 100%;
//   height: 42px;
// }
@keyframes sound {
  0% {
    //  opacity: .35;
    height: 10%;
  }

  100% {
    // opacity: 1;
    height: 100%;
  }
}

.wave__bar--sound:nth-child(1) {
  animation-duration: 474ms;
}

.wave__bar--sound:nth-child(2) {
  animation-duration: 433ms;
}

.wave__bar--sound:nth-child(3) {
  animation-duration: 407ms;
}

.wave__bar--sound:nth-child(4) {
  animation-duration: 458ms;
}

.wave__bar--sound:nth-child(5) {
  animation-duration: 400ms;
}

.wave__bar--sound:nth-child(6) {
  animation-duration: 427ms;
}

.wave__bar--sound:nth-child(7) {
  animation-duration: 441ms;
}

.wave__bar--sound:nth-child(8) {
  animation-duration: 419ms;
}

.wave__bar--sound:nth-child(9) {
  animation-duration: 487ms;
}

.wave__bar--sound:nth-child(10) {
  animation-duration: 442ms;
}

.wave__bar--sound:nth-child(11) {
  animation-duration: 474ms;
}

.wave__bar--sound:nth-child(12) {
  animation-duration: 433ms;
}

.wave__bar--sound:nth-child(13) {
  animation-duration: 407ms;
}

.wave__bar--sound:nth-child(14) {
  animation-duration: 458ms;
}

.wave__bar--sound:nth-child(15) {
  animation-duration: 400ms;
}

.wave__bar--sound:nth-child(16) {
  animation-duration: 427ms;
}

.wave__bar--sound:nth-child(17) {
  animation-duration: 441ms;
}

.wave__bar--sound:nth-child(18) {
  animation-duration: 419ms;
}

.wave__bar--sound:nth-child(19) {
  animation-duration: 487ms;
}

.wave__bar--sound:nth-child(20) {
  animation-duration: 442ms;
}

.wave__bar--sound:nth-child(21) {
  animation-duration: 474ms;
}

.wave__bar--sound:nth-child(22) {
  animation-duration: 433ms;
}

.wave__bar--sound:nth-child(23) {
  animation-duration: 407ms;
}

.wave__bar--sound:nth-child(24) {
  animation-duration: 458ms;
}

.wave__bar--sound:nth-child(25) {
  animation-duration: 400ms;
}

.wave__bar--sound:nth-child(26) {
  animation-duration: 427ms;
}

.wave__bar--sound:nth-child(27) {
  animation-duration: 441ms;
}

.wave__bar--sound:nth-child(28) {
  animation-duration: 419ms;
}

.wave__bar--sound:nth-child(29) {
  animation-duration: 487ms;
}

.wave__bar--sound:nth-child(30) {
  animation-duration: 442ms;
}

.wave__bar--sound:nth-child(31) {
  animation-duration: 400ms;
}

.wave__bar--sound:nth-child(32) {
  animation-duration: 427ms;
}

.wave__bar--sound:nth-child(33) {
  animation-duration: 407ms;
}

.wave__bar--sound:nth-child(34) {
  animation-duration: 458ms;
}

.wave__bar--sound:nth-child(35) {
  animation-duration: 400ms;
}

.wave__bar--sound:nth-child(36) {
  animation-duration: 427ms;
}

.wave__bar--sound:nth-child(37) {
  animation-duration: 441ms;
}

.wave__bar--sound:nth-child(38) {
  animation-duration: 419ms;
}

.wave__bar--sound:nth-child(39) {
  animation-duration: 487ms;
}

.wave__bar--sound:nth-child(40) {
  animation-duration: 442ms;
}

.wave__bar--sound:nth-child(41) {
  animation-duration: 474ms;
}

.wave__bar--sound:nth-child(42) {
  animation-duration: 433ms;
}

.wave__bar--sound:nth-child(43) {
  animation-duration: 407ms;
}

.wave__bar--sound:nth-child(44) {
  animation-duration: 458ms;
}

.wave__bar--sound:nth-child(45) {
  animation-duration: 400ms;
}

.wave__bar--sound:nth-child(46) {
  animation-duration: 427ms;
}

.wave__bar--sound:nth-child(47) {
  animation-duration: 441ms;
}

.wave__bar--sound:nth-child(48) {
  animation-duration: 419ms;
}

.wave__bar--sound:nth-child(49) {
  animation-duration: 487ms;
}

.wave__bar--sound:nth-child(50) {
  animation-duration: 442ms;
}

.wave__bar--sound:nth-child(51) {
  animation-duration: 474ms;
}

.wave__bar--sound:nth-child(52) {
  animation-duration: 433ms;
}

.wave__bar--sound:nth-child(53) {
  animation-duration: 407ms;
}

.wave__bar--sound:nth-child(54) {
  animation-duration: 458ms;
}

.wave__bar--sound:nth-child(55) {
  animation-duration: 400ms;
}

.wave__bar--sound:nth-child(56) {
  animation-duration: 427ms;
}

.wave__bar--sound:nth-child(57) {
  animation-duration: 441ms;
}

.wave__bar--sound:nth-child(58) {
  animation-duration: 419ms;
}

.wave__bar--sound:nth-child(59) {
  animation-duration: 487ms;
}

.wave__bar--sound:nth-child(60) {
  animation-duration: 442ms;
}

.wave__bar--sound:nth-child(61) {
  animation-duration: 400ms;
}

.wave__bar--sound:nth-child(62) {
  animation-duration: 427ms;
}

.wave__bar--sound:nth-child(63) {
  animation-duration: 441ms;
}

.wave__bar--sound:nth-child(64) {
  animation-duration: 419ms;
}
</style>
