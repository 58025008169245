<template>
  <div id="app">
    <nav-bar
      v-if="loading && $route.name !== 'work'"
      :headline="general.headline"
    />
    <router-view />
    <footer-comp class="footer" />
    <transition name="opening">
      <opening-view v-if="$store.state.Opening" />
    </transition>
    <transition name="fade">
      <change-page v-if="isCovering && !opening" class="fixedEle" />
    </transition>
    <transition name="fade">
      <reveal-page v-if="isRevealing && !opening" class="fixedEle" />
    </transition>
  </div>
</template>

<script>
import ChangePage from "./components/ChangePage.vue";
import FooterComp from "./components/FooterComp.vue";
import NavBar from "./components/NavBar.vue";
import OpeningView from "./components/openingView.vue";
import { mapState } from "vuex";
import RevealPage from "./components/RevealPage.vue";
export default {
  components: { NavBar, FooterComp, OpeningView, ChangePage, RevealPage },
  data() {
    return {
      // Initialize "response"
      general: null,
      loading: false,
      opening: true,
    };
  },
  computed: {
    ...mapState(["isCovering", "isRevealing"]),
  },
  methods: {
    async getContent() {
      // Query the API and assign the response to "response"
      // const response = await this.$prismic.client.query("");
      const response = await this.$prismic.client.getSingle("general");
      this.general = response.data;
      this.loading = true;
    },
  },
  created() {
    // Call the API query method
    this.getContent();
    console.log(
      `%cWebsite designed with love by 🖍️ Sofia Girelli, Developed with struggle by 👨‍💻 Lorenzo Tempesti`,
      `font-weight:bold; color:palegreen; padding: 12px 16px; background:sienna; border-radius: 16px;line-height: 160%; font-size: 0.8rem`
    );
  },
  mounted() {
    this.opening = false;
    if (window.innerWidth < 520) {
      this.$store.commit("setMobile", true);
    }
    this.$store.commit("setOpening", false);
  },
};
</script>

<style lang="scss">
body {
  // scroll-behavior: smooth;
  background: white;
}

@font-face {
  font-family: "Plain";
  src: url("assets/fonts/Plain-Medium.woff2") format("woff2"),
    url("assets/fonts/Plain-Medium.woff") format("woff"),
    url("assets/fonts/Plain-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

h2,
h3 {
  font-weight: normal;
}

@font-face {
  font-family: "HelveticaNow";
  src: url("assets/fonts/HelveticaNowDisplay-Bold.woff2") format("woff2"),
    url("assets/fonts/HelveticaNowDisplay-Bold.woff") format("woff"),
    url("assets/fonts/HelveticaNowDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  color: black;

  a {
    color: black;
    text-decoration: none;
  }
}

#app {
  // height: 100%;
  position: relative;
  width: 100%;
  min-height: 100vh;
  @media screen and (max-width: 520px) {
      min-height: calc(100vh - 66px - 78px);
      min-height: calc(100dvh - 66px - 78px);
    }
}

.footer {
  // position: absolute;
  bottom: 0;
  left: 0;
  z-index: 100;
}

.opening-leave-active {
  animation: openingBlock 700ms 3s ease-in;
}

@keyframes openingBlock {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100vh);
    // filter: blur(10px);
    // backdrop-filter: blur(20px);
  }
}
.opening-enter, .opening-leave-to /* .fade-leave-active below version 2.1.8 */ {
  // opacity: 0;
}

// .fade-enter-active, .fade-leave-active {
//   transition: opacity .2s;
// }
// .fade-enter, .fade-leave-to {
//   opacity: 0;
// }
.fixedEle {
  // position: fixed;
  // top: 0;
  // left: 0;
}
</style>
