<template>
</div>
  <div class="works__page" :class="filOpen && 'works__page--expans'">
    <div class="works__filter">
      <filter-title class="works__desk" />
      <filter-list :tags="tags"  v-on:close-fil="seca($event)"/>
    </div>
    <h3 class="works__headline">
      Music, Soundtrack and Sound Design for Visual Media
    </h3>
    <div class="works__mob works__filterTitle" @click="trigFil">
      <filter-title :mobOpen="filOpen"/>
    </div>
    <div class="works__container">
      <transitionGroup name="workFade">
      <work-elem  :prj="w.project" v-for="w in workFilter" :key="w.project.id" appear/>
      </transitionGroup>
    </div>
    <get-in-touch class="works__concat" />
  </div>
</template>

<script>
import FilterList from "@/components/FilterList.vue";
import WorkElem from "@/components/WorkElem.vue";
import GetInTouch from "@/components/GetInTouch.vue";
import FilterTitle from "@/components/FilterTitle.vue";
export default {
  components: { FilterList, WorkElem, GetInTouch, FilterTitle },
  data() {
    return {
      loading: false,
      works: null,
      tags: null,
      filOpen: false,
    };
  },
  computed: {
    workFilter() {
      if (this.$store.state.selFilter === "All") return this.works;
      else
        return this.works.filter((item) =>
          item.project.tags.includes(this.$store.state.selFilter)
        );
    },
  },
  methods: {
    trigFil() {
      // this.filOpen=this.FilOpen ? false : true;
      this.filOpen = !this.filOpen ? true : false;
    },
    seca(n){
      this.filOpen = false
    },
    async getContent() {
      // Query the API and assign the response to "response"
      // const response = await this.$prismic.client.query("");
      const response = await this.$prismic.client.getSingle("projects_page");
      this.works = response.data.project_list;
      let tagsArr = [];
      this.works.forEach((n) => {
        tagsArr.push(n.project.tags);
      });
      let tagsUn = [].concat(...tagsArr);
      this.tags = ["All", ...new Set(tagsUn)];
      this.loading = true;
    },
  },
  created() {
    // Call the API query method
    this.getContent();
  },
};
</script>

<style lang="scss" scoped>
.works {
  &__page {
    min-height: 100vh;
    width: 100%;
    // max-width: 1800px;
    margin: auto;
    padding: 20vh 32px 32px 32px;
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: 20% auto;
    row-gap: 32px;
    column-gap: 32px;
    transition: all 300ms ease-out;

    @media screen and (max-width: 520px) {
      padding: 120px 20px 32px 20px;
      grid-template-rows: auto auto auto auto;
      // grid-template-rows: repeat(3, auto);
      grid-template-columns: 0 100%;
      column-gap: 0;
      row-gap: 0px;
      overflow: hidden;
    }

    &--expans {
      grid-template-columns: clamp(224px, 50%, 350px) 100%;
    }
  }

  &__filter {
    grid-column: 1;
    grid-row: 2 / end;

    @media screen and (max-width: 520px) {
      grid-row: 3;
      overflow: hidden;

      // min-height: 400px;
      div {
        width: 300px;
      }
    }
  }

  &__filterTitle {
    grid-column: 1 / span 2;
    grid-row: 2;
    height: 100%;
    width: 100%;
    align-self: start;
  }

  &__headline {
    grid-column: 1 / span 2;
    grid-row: 1;
    @include plain;
    font-size: 22px;
    color: black;
    width: 100%;

    @media screen and (max-width: 520px) {
      font-size: 12px;
    }
  }

  &__container {
    grid-column: 2;
    grid-row: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    span{
      display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    }

    @media screen and (max-width: 520px) {
      grid-column: 2;
      grid-row: 3;
    }
  }

  &__concat {
    padding-top: 52px;
    grid-column: 2;
    grid-row: 3;

    @media screen and (max-width: 520px) {
      grid-column: 1 / end;
      grid-row: 4;
    }
  }

  &__desk {
    @media screen and (max-width: 520px) {
      display: none;
    }

  }

  &__mob {
    @media screen and (min-width: 520px) {
      display: none;
    }
  }
}
// .workFade-leave-active , .workFade-enter.active{
//   // animation: openingBlock 700ms 3s ease-in;
//   transition: all 2s ease-in-out;
// }

// .workFade-enter-from, .workFade-leave-to /* .fade-leave-active below version 2.1.8 */ {
//   transform: translateX(400px);
//   opacity: 0;
// }

.workFade-enter-active,
.workFade-leave-active {
  transition: all 0.5s ease;
}
.workFade-enter,
.workFade-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
