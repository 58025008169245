<template>
  <div class="change__container">
    <div class="change__cover" id="cover1"></div>
    <div class="change__cover" id="cover2"></div>
    <div class="change__cover" id="cover3"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  mounted() {
    document.body.style.overflow = "hidden";

    const b1 = document.querySelector("#cover1");
    b1.style.setProperty("--alt", "120vh");
    const b2 = document.querySelector("#cover2");
    b2.style.setProperty("--alt", "140vh");
    const b3 = document.querySelector("#cover3");
    b3.style.setProperty("--alt", "120vh");
  },
};
</script>

<style lang="scss" scoped>
.change__container {
  @media screen and (max-width: 520px) {
    display: none;
  }
  z-index: 200;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  // background-color: black;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
}

.change__cover {
  animation: coverScroll 600ms ease-out forwards;
  position: absolute;
  width: calc(40% + 16px);
  background: black;
  height: 100vh;
  // border: 1px solid red;
  // top: 100vh;
}

#cover1 {
  left: calc(-10% - 24px);
  height: var(--alt);
}
#cover2 {
  left: calc(30% - 8px);
}
#cover3 {
  left: calc(70% + 8px);
}

@keyframes coverScroll {
  0% {
    bottom: calc(-1 * var(--alt));
  }
  100% {
    bottom: 0;
    // top: 0vh;
  }
}
</style>
