<template>
  <div data-scroll-container id="scroll-container" class="page">
    <div class="text-container">
      <p id="animatedText" data-scroll class="splitting" data-splitting="lines">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
        rhoncus tempor dui, id interdum purus. Curabitur sit amet mattis urna.
        Praesent laoreet ligula sodales posuere feugiat. Mauris consectetur
        ultricies nisl id aliquet. Aliquam eu turpis vitae libero viverra
        maximus. Donec eu lacus maximus, molestie nunc vel, volutpat sem.
        Maecenas pulvinar nisi ac felis tempor imperdiet. Proin pulvinar aliquam
        enim vel sagittis. Morbi ut molestie nisl.
      </p>
    </div>
    <div class="imgCont">
      <img src="../assets/img/heroface.png" class="imgFace" alt="" />
    </div>
    <div class="text-container">
      <p
        id="animatedText"
        data-scroll
        class="splitting my-element"
        data-splitting="lines"
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
        rhoncus tempor dui, id interdum purus. Curabitur sit amet mattis urna.
        Praesent laoreet ligula sodales posuere feugiat. Mauris consectetur
        ultricies nisl id aliquet. Aliquam eu turpis vitae libero viverra
        maximus. Donec eu lacus maximus, molestie nunc vel, volutpat sem.
        Maecenas pulvinar nisi ac felis tempor imperdiet. Proin pulvinar aliquam
        enim vel sagittis. Morbi ut molestie nisl.
      </p>
    </div>
    <div class="text-container">
      <p id="animatedText" data-scroll class="splitting" data-splitting="lines">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
        rhoncus tempor dui, id interdum purus. Curabitur sit amet mattis urna.
        Praesent laoreet ligula sodales posuere feugiat. Mauris consectetur
        ultricies nisl id aliquet. Aliquam eu turpis vitae libero viverra
        maximus. Donec eu lacus maximus, molestie nunc vel, volutpat sem.
        Maecenas pulvinar nisi ac felis tempor imperdiet. Proin pulvinar aliquam
        enim vel sagittis. Morbi ut molestie nisl.
      </p>
    </div>
    <div class="text-container">
      <p id="animatedText" data-scroll class="splitting" data-splitting="lines">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
        rhoncus tempor dui, id interdum purus. Curabitur sit amet mattis urna.
        Praesent laoreet ligula sodales posuere feugiat. Mauris consectetur
        ultricies nisl id aliquet. Aliquam eu turpis vitae libero viverra
        maximus. Donec eu lacus maximus, molestie nunc vel, volutpat sem.
        Maecenas pulvinar nisi ac felis tempor imperdiet. Proin pulvinar aliquam
        enim vel sagittis. Morbi ut molestie nisl.
      </p>
    </div>
  </div>
</template>

<script>
import Lenis from "@studio-freight/lenis";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Splitting from "splitting";

export default {
  methods: {
    callAnim() {
      console.log("DAJJJJEEEE");
    },
  },
  mounted() {
    Splitting({
      by: "lines",
    });

    //  const images = document.querySelectorAll('.parallax-image');
    const para = document.querySelectorAll(".splitting");
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      lerp: 0.1,
      // smooth: true,
    });

    lenis.on("scroll", (e) => {
      // console.log(e)
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);
    const cont = document.querySelector(".text-container");

    para.forEach((word) => {
      let w = word.children;
      for (let x = 0; x < w.length; x++) {
        w[x].style.setProperty("--delay", 10 * x + "ms");

        w[x].style.animationDelay = 10 * x + "ms";
      }
      // w.forEach((x, index) => {
      //   x.style.animationDelay = 100*x + "ms"
      // })
    });

    para.forEach((item) => {
      gsap.to(item, {
        scrollTrigger: {
          trigger: item,
          start: "top 80%", // Inizia l'animazione quando il testo raggiunge l'80% dal top del viewport
          end: "bottom 20%",
          scrub: true, // Smooth dell'animazione durante lo scroll
          markers: true, // Questa opzione può essere rimossa, serve solo per mostrare i markers durante lo sviluppo
          onEnter: () => item.classList.add("is-inview"),
        },
      });
    });

    const face = document.querySelector(".imgFace");
    const con = document.querySelector(".imgCont");

    gsap.from(face, {
      scrollTrigger: {
        trigger: face,
        start: "top 80%", // Inizia l'animazione quando il testo raggiunge l'80% dal top del viewport
        end: "bottom 20%",
        scrub: true, // Smooth dell'animazione durante lo scroll
        markers: true, // Questa opzione può essere rimossa, serve solo per mostrare i markers durante lo sviluppo
      },
      duration: 1, // Durata dell'animazione per ogni riga
      y: 150, // Muovi le righe verso l'alto di 50 pixel
      opacity: 0, // Parti da un'opacità di 0
      stagger: 0.1, // Ritarda l'inizio dell'animazione per ogni riga di 0.1 secondi
      delay: 0.5, // Ritarda l'inizio totale dell'animazione di 0.5 secondi
    });
    //   gsap.from(item, {
    //     scrollTrigger: {
    //       trigger: item, // Identificatore del testo da animare
    //       start: "top 80%", // Inizia l'animazione quando il testo raggiunge l'80% dal top del viewport
    //       end: "bottom 20%", // Termina l'animazione quando il testo raggiunge il 20% dal bottom del viewport
    //       scrub: true, // Smooth dell'animazione durante lo scroll
    //       markers: true, // Questa opzione può essere rimossa, serve solo per mostrare i markers durante lo sviluppo
    //     },
    //     duration: 1,  // Durata dell'animazione per ogni riga
    //     y: 250,        // Muovi le righe verso l'alto di 50 pixel
    //     opacity: 0,   // Parti da un'opacità di 0
    //     stagger: 0.1, // Ritarda l'inizio dell'animazione per ogni riga di 0.1 secondi
    //     delay: 0.5,   // Ritarda l'inizio totale dell'animazione di 0.5 secondi
    //   });
    // })

    // para.forEach((item) => {
    //   // const image = item.querySelector(".grid__item-img");

    //   gsap
    //     .timeline({
    //       scrollTrigger: {
    //         trigger: item,
    //         start: "top top",
    //         end: "bottom top",
    //         scrub: true,
    //       },
    //     })
    //     .set(item, {
    //       // transformOrigin: `${gsap.utils.random(0, 1) > 0.5 ? 0 : 100}% 100%`,
    //     })
    //     .to(item, {
    //       // ease: "none",
    //       scale: 1.2,
    //     });
    // });

    // lenis.lerp({
    //   from: 0,
    //   to: 100,
    //   ease: "easeInOutSine",
    //   onUpdate: (value) => {
    //     // Aggiorna l'elemento nel viewport con il nuovo valore
    //     document.querySelector(
    //       ".my-element"
    //     ).style.transform = `translateY(${value}px)`;
    //   },
    // });

    // this.scroll = new LocomotiveScroll({
    //   el: document.querySelector('#scroll-container'),
    //   smooth: true,
    //   lerp: 0.05 // Imposta qui il tuo valore lerp
    // });
    // this.scroll.on('call', func => {
    //   // console.log("SUCA")
    //   // this.call(...func);
    //   // this.callAnim
    // })
    // this.$nextTick(function () {
    //   const par = document.querySelectorAll(".splitting")
    // par.forEach((word) => {

    //     let w = word.children
    //     console.log(word.children)
    //     for(let x = 0; x < w.length; x++){
    //       w[x].style.animationDelay = 10*x + "ms"
    //     }
    //     // w.forEach((x, index) => {
    //     //   x.style.animationDelay = 100*x + "ms"
    //     // })
    // })
    // })
  },
  beforeDestroy() {
    // È importante distruggere l'istanza quando il componente viene distrutto
    // this.scroll.destroy();
  },
};
</script>

<style lang="scss" scoped>
html.has-scroll-smooth {
  // overflow: hidden;
}

html.has-scroll-dragging {
  user-select: none;
}

[data-scroll] {
  will-change: transform;
  transform: translate3d(0, 0, 0);
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.page {
  min-height: 200vh;
  padding-top: 700px;
}

.text-container {
  margin: auto;
  // padding-top:200px;
  width: 400px;
  margin-bottom: 200px;
  @include plain;
  overflow: hidden;
  position: relative;
}

.word {
  opacity: 0;
}

.is-inview .word {
  // color: red;
  display: inline-block;
  animation: wordSlide 300ms ease-out forwards;
  // overflow: hidden;
  position: relative;

  &::after {
    animation: afterSlide 300ms ease-out forwards;
    background-color: white;
    content: "";
    height: 150%;
    left: 0;
    position: absolute;
    animation-delay: var(--delay);
    // top: 100%;
    width: 100%;
    z-index: 2;
  }
}

@keyframes wordSlide {
  0% {
    // opacity: 0;
    transform: translateY(26px);
  }

  100% {
    opacity: 1;
    transform: translate(0px);
  }
}

@keyframes afterSlide {
  0% {
    top: 0;
  }

  100% {
    opacity: 1;
    top: 100%;
  }
}

.imgFace {
  margin: 150px auto;
  height: 300px;
  width: auto;
}
</style>
