<template>
  <div class="change__container">
    <div class="change__bar" id="bar1"></div>
    <div class="change__bar" id="bar2"></div>
    <div class="change__bar" id="bar3"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  mounted() {
    const b1 = document.querySelector("#bar1");
    b1.style.setProperty("--alt", "120vh");
    const b2 = document.querySelector("#bar2");
    b2.style.setProperty("--alt", "140vh");
    const b3 = document.querySelector("#bar3");
    b3.style.setProperty("--alt", "120vh");
  },
  beforeDestroy() {
    document.body.style.overflow = "auto";
  },
};
</script>

<style lang="scss" scoped>
.change__container {
  @media screen and (max-width: 520px) {
    display: none;
  }
  z-index: 200;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  // background-color: black;
  overflow: hidden;
  // user-select: none;
  // pointer-events: none;
}

.change__bar {
  animation: barScroll 600ms ease-in forwards;
  position: absolute;
  width: calc(40% + 16px);
  // background: red;
  background: black;
  height: 100vh;
  // border: 1px solid red;
  // top: 100vh;
}

#bar1 {
  left: calc(-10% - 24px);
  height: var(--alt);
}
#bar2 {
  left: calc(30% - 8px);
}
#bar3 {
  left: calc(70% + 8px);
}

@keyframes barScroll {
  0% {
    // bottom: calc(-1 * var(--alt));
    top: 0;
  }
  100% {
    top: calc(-1 * var(--alt));
    // top: 0vh;
  }
}
</style>
