<template>
  <div class="wave__container" :class="work && 'wave__container--work'">
    <div v-for="w in 54" :key="w + 'wave'" class="wave__bar" :class="sound && 'wave__bar--sound'"></div>
    <div v-if="work" class="wave__duration"></div>
  </div>
</template>

<script>
export default {
  props: {
    sound: Boolean,
    work: Boolean,
  },
  data() {
    return {
      videoElement: null,
    };
  },
  mounted() {
    if (this.$route.name === 'work') {
      const muxPlayer = document.querySelector("mux-player").shadowRoot;
      this.videoElement = muxPlayer
        .querySelector("media-theme")
        .querySelector("mux-video")
        .shadowRoot.querySelector("video");
      const cover = document.querySelector(".wave__duration");
      this.videoElement.addEventListener("timeupdate", (event) => {
        // console.log(this.videoElement.currentTime / this.videoElement.duration)
        let wid =
          (1 - this.videoElement.currentTime / this.videoElement.duration) * 100;
        cover.style.width = wid + "%";
      });
    }
  },
  updated() { },
};
</script>

<style lang="scss" scoped>
.wave {
  &__container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    height: 42px;
    align-items: center;
    position: relative;

    &--work {
      aspect-ratio: auto;
      width: 600px;
      margin: auto;
      height: 100px;

      @media screen and (max-width: 520px) {
        height: 50px;
      }
    }
  }

  &__bar {
    height: 2px;
    width: 2px;
    background: black;

    // border-radius: 8px;
    &--sound {
      animation: sound 0 - 800ms ease-in-out infinite alternate;
    }
  }

  &__duration {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0.8;
  }
}

@keyframes sound {
  0% {
    //  opacity: .35;
    height: 10%;
  }

  100% {
    // opacity: 1;
    height: 100%;
  }
}

.wave__bar--sound:nth-child(1) {
  animation-duration: 474ms;
}

.wave__bar--sound:nth-child(2) {
  animation-duration: 433ms;
}

.wave__bar--sound:nth-child(3) {
  animation-duration: 407ms;
}

.wave__bar--sound:nth-child(4) {
  animation-duration: 458ms;
}

.wave__bar--sound:nth-child(5) {
  animation-duration: 400ms;
}

.wave__bar--sound:nth-child(6) {
  animation-duration: 427ms;
}

.wave__bar--sound:nth-child(7) {
  animation-duration: 441ms;
}

.wave__bar--sound:nth-child(8) {
  animation-duration: 419ms;
}

.wave__bar--sound:nth-child(9) {
  animation-duration: 487ms;
}

.wave__bar--sound:nth-child(10) {
  animation-duration: 442ms;
}

.wave__bar--sound:nth-child(11) {
  animation-duration: 474ms;
}

.wave__bar--sound:nth-child(12) {
  animation-duration: 433ms;
}

.wave__bar--sound:nth-child(13) {
  animation-duration: 407ms;
}

.wave__bar--sound:nth-child(14) {
  animation-duration: 458ms;
}

.wave__bar--sound:nth-child(15) {
  animation-duration: 400ms;
}

.wave__bar--sound:nth-child(16) {
  animation-duration: 427ms;
}

.wave__bar--sound:nth-child(17) {
  animation-duration: 441ms;
}

.wave__bar--sound:nth-child(18) {
  animation-duration: 419ms;
}

.wave__bar--sound:nth-child(19) {
  animation-duration: 487ms;
}

.wave__bar--sound:nth-child(20) {
  animation-duration: 442ms;
}

.wave__bar--sound:nth-child(21) {
  animation-duration: 474ms;
}

.wave__bar--sound:nth-child(22) {
  animation-duration: 433ms;
}

.wave__bar--sound:nth-child(23) {
  animation-duration: 407ms;
}

.wave__bar--sound:nth-child(24) {
  animation-duration: 458ms;
}

.wave__bar--sound:nth-child(25) {
  animation-duration: 400ms;
}

.wave__bar--sound:nth-child(26) {
  animation-duration: 427ms;
}

.wave__bar--sound:nth-child(27) {
  animation-duration: 441ms;
}

.wave__bar--sound:nth-child(28) {
  animation-duration: 419ms;
}

.wave__bar--sound:nth-child(29) {
  animation-duration: 487ms;
}

.wave__bar--sound:nth-child(30) {
  animation-duration: 442ms;
}

.wave__bar--sound:nth-child(31) {
  animation-duration: 400ms;
}

.wave__bar--sound:nth-child(32) {
  animation-duration: 427ms;
}

.wave__bar--sound:nth-child(33) {
  animation-duration: 407ms;
}

.wave__bar--sound:nth-child(34) {
  animation-duration: 458ms;
}

.wave__bar--sound:nth-child(35) {
  animation-duration: 400ms;
}

.wave__bar--sound:nth-child(36) {
  animation-duration: 427ms;
}

.wave__bar--sound:nth-child(37) {
  animation-duration: 441ms;
}

.wave__bar--sound:nth-child(38) {
  animation-duration: 419ms;
}

.wave__bar--sound:nth-child(39) {
  animation-duration: 487ms;
}

.wave__bar--sound:nth-child(40) {
  animation-duration: 442ms;
}

.wave__bar--sound:nth-child(41) {
  animation-duration: 474ms;
}

.wave__bar--sound:nth-child(42) {
  animation-duration: 433ms;
}

.wave__bar--sound:nth-child(43) {
  animation-duration: 407ms;
}

.wave__bar--sound:nth-child(44) {
  animation-duration: 458ms;
}

.wave__bar--sound:nth-child(45) {
  animation-duration: 400ms;
}

.wave__bar--sound:nth-child(46) {
  animation-duration: 427ms;
}

.wave__bar--sound:nth-child(47) {
  animation-duration: 441ms;
}

.wave__bar--sound:nth-child(48) {
  animation-duration: 419ms;
}

.wave__bar--sound:nth-child(49) {
  animation-duration: 487ms;
}

.wave__bar--sound:nth-child(50) {
  animation-duration: 442ms;
}

.wave__bar--sound:nth-child(51) {
  animation-duration: 474ms;
}

.wave__bar--sound:nth-child(52) {
  animation-duration: 433ms;
}

.wave__bar--sound:nth-child(53) {
  animation-duration: 407ms;
}

.wave__bar--sound:nth-child(54) {
  animation-duration: 458ms;
}

.wave__bar--sound:nth-child(55) {
  animation-duration: 400ms;
}

.wave__bar--sound:nth-child(56) {
  animation-duration: 427ms;
}

.wave__bar--sound:nth-child(57) {
  animation-duration: 441ms;
}

.wave__bar--sound:nth-child(58) {
  animation-duration: 419ms;
}

.wave__bar--sound:nth-child(59) {
  animation-duration: 487ms;
}

.wave__bar--sound:nth-child(60) {
  animation-duration: 442ms;
}

.wave__bar--sound:nth-child(61) {
  animation-duration: 400ms;
}

.wave__bar--sound:nth-child(62) {
  animation-duration: 427ms;
}

.wave__bar--sound:nth-child(63) {
  animation-duration: 441ms;
}

.wave__bar--sound:nth-child(64) {
  animation-duration: 419ms;
}
</style>
