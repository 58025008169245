<template>
  <div
    class="footer__container"
    :class="$route.name === 'info' && 'footer__container--dark'"
  >
    <div class="footer__copyright">2023 Federico Cerati</div>
    <div class="footer__copyright">Henford Music</div>
    <!-- <a href="/">Terms & Rights </a> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  &__container {
    width: 100%;
    padding: 24px;
    justify-content: space-between;
    display: flex;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    @include plain;
    a,
    div {
      color: black;
      font-size: 12px;
    }

    a {
      text-decoration: none;
    }

    &--dark {
      color: white;
      background: black;
      a,
      div {
        color: white;
      }
    }
  }
}
</style>
